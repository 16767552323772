<template>
    <div style="width:100%;color:#606266">
        <div class="users">
            <el-button type="warning" @click="leaveRoom">离开房间</el-button>
            <!-- <p>选手 Contestants</p>
            <div id="student" class="smallWindow"></div>
            <p>工作人员 Staff</p>
            <div id="educator" class="smallWindow"></div>
            <div id="audio"></div> -->
        </div>
        <el-button type="primary" @click="prePage">上一页</el-button>
        <el-button type="primary" @click="nextPage">下一页</el-button>
        <pdf :src="'https://mainbucket.learningfirst.cn/21-22%20FBLA_Econ/' + event_name + '.pdf'" :page="pageNum"></pdf>
        <el-button type="primary" @click="prePage">上一页</el-button>
        <el-button type="primary" @click="nextPage">下一页</el-button>
    </div>
</template>

<script>
import * as QNRTC from 'pili-rtc-web'
import pdf from 'vue-pdf'
import { createPresentationRoomToken, getPresentationInfo } from '../../api/index'
import {getUserId} from '../../utils/store'

const myRoom = new QNRTC.TrackModeSession()

console.log('current version is', QNRTC.version)
export default {
  components:{
    pdf
  },
  data () {
    return {
      audioDevice: '',
      videoDevice: '',
      audioDevices: [],
      videoDevices: [],
      roomToken: '',
      userList: [],
      currentUser: '',
      lastUser: '',
      showInfo: false,
      showToken: false,
      room: '',
      userId: '',
      userToken: '',
      room_list: [],
      type: '',
      currentRoom: '',
      message:'',
      toUserId:'',
      showChat:false,
      chatMessage:[],
      allMessages:{},
      newMessages:[],
      isInRoom:false,
      room_id:'',
      time_slot:[],
      publicMessages:[],
      showPublic:[],
      roomId:'',
      screenTrackId:'',
      screenTrack:'',
      remoteTrackId:'',
      tracks:[],
      application_id:'',
      event_name:'',
      pageNum:1,
      pageTotalNum:0,
      url:''
    }
  },
  mounted () {
    this.roomId = this.$route.query.roomId
    this.application_id = this.$route.query.application_id
    let event_name = this.$route.query.event_name
    // if(event_name=='Hospitality & Event Management'){
    //   this.event_name = 'Hospitality_and_Event_Management'
    // }else if(event_name=='Sports & Entertaiment Management'){
    //   this.event_name = 'Sports_%26_Entertaiment_Management.pdf'
    // }else if(event_name=='Banking & Financial Systems'){
    //   this.event_name ='Banking_and_Financial_Systems'
    // }else if(event_name =='Critical Thinking'){
    //   this.event_name ='ML-Critical-Thinking'
    // }else{
    //   event_name = event_name.replace(/ /g,'_')
      this.event_name = event_name
    // }
    // this.getPresentationInfo()
    window.addEventListener( 'beforeunload', e => this.leaveRoom() );
    // this.joinRoom()
  },
  destroyed(){
      myRoom.leaveRoom();
      for (const track of this.tracks) {
        track.release();
      }
  },
  methods: {
    addDeviceToSelect (deviceInfo) {
      let audioDevices = []
      let videoDevices = []
      deviceInfo.forEach(info => {
        if (info.kind === 'audioinput') {
          audioDevices.push(info)
        } else if (info.kind === 'videoinput') {
          videoDevices.push(info)
        }
      })
      this.audioDevices = audioDevices
      this.videoDevices = videoDevices
    },
    async joinRoom () {
      // 从输入框中获取 roomToken

      this.showInfo=false

      let roomToken

      const result = await this.createRoomToken()
      console.log(11111,result)
      if(result.code==0){
        roomToken = result.data.token
        this.room_id = result.data.room_id
      }else{
        this.$message(result.msg)
        return;
      }

      try {
        // 加入房间
        await myRoom.joinRoomWithToken(roomToken)

        // 如果加入房间后发现房间已经有人发布，就订阅他
      
        const users = myRoom.users;

        for (let user of users) {
          // 每个用户当前的 TrackInfo 列表
          let userTrackInfoList = user.publishedTrackInfo;
          this.subscribeTracks(userTrackInfoList)

        }


      } catch (e) {
        console.error(e)
        this.isInRoom = false
        this.$message(`加入房间失败！ErrorCode: ${e.code || ''}`)
        return
      }


        myRoom.on("track-add", (tracks) => {
            this.subscribeTracks(tracks);       
        });

        myRoom.on("user-leave", user => {
            let thisNode=document.getElementById(user.userId);
            thisNode.parentElement.removeChild(thisNode);
        })

        myRoom.on("messages-received", msgData => {
            this.$message(msgData[0].data)
          })


      await this.publish()


    },
    updateMessage(){
      this.chatMessage = this.allMessages[this.toUserId]
    },

    async publishScreen () {
      if(this.screenTrackId!=''){
        this.$message('请勿重复分享！')
        return;
      }
      let tracks
      try {
        // 通过用户在页面上指定的设备发起采集
        // 也可以不指定设备，这样会由浏览器自动选择
        tracks = await QNRTC.deviceManager.getLocalTracks({
          screen: {
              enabled: true,
              source: "window",
              bitrate: 600,
              height: 720,
              width: 1280,
              tag:'screen'
          },
          audio: {
              enabled: false,
          }
        })
      } catch (e) {
        console.error(e)
        this.$message(`采集失败，请检查您的设备。ErrorCode: ${e.code}`)
        return
      } 

      try {
        // 发布采集流
        await myRoom.publish(tracks)
      } catch (e) {
        console.error(e)
        this.$message(`发布失败，ErrorCode: ${e.code}`)
      }
      
      this.screenTrackId=tracks[0].info.trackId
      this.screenTrack = tracks[0]

      if(this.remoteTrackId!=''){
        myRoom.unsubscribe([this.remoteTrackId]);
        this.remoteTrackId=''
      }

      let ppt = document.getElementById('ppt');
      for (const track of tracks) {
            if (track.info.kind === "video") {
               
                track.play(ppt, true);
            }
        }
    },
    async publish () {
      let tracks
      try {
        // 通过用户在页面上指定的设备发起采集
        // 也可以不指定设备，这样会由浏览器自动选择
        tracks = await QNRTC.deviceManager.getLocalTracks({
          video: {
            enabled: true,
            deviceId: this.videoDevice,
            bitrate: 1000,
            height:720,
            width:1280
          },
          screen: {
              enabled: true,
              source: "window",
              bitrate: 600,
              height: 720,
              width: 1280,
              tag:'screen'
          },
          audio: {
            enabled: true,
            deviceId: this.audioDevice
          }
        })
      } catch (e) {
        console.error(e)
        alert(`采集失败，请检查您的设备。ErrorCode: ${e.code}`)
        return
      }

              //每个人的block
                let div = document.createElement('div');
                div.setAttribute('id',tracks[0].userId);
                div.style.width = '33%';
                let student = document.getElementById('student');
                student.appendChild(div)
                //摄像头
                let camera = document.createElement('div');
                camera.setAttribute('id','camera'+tracks[0].userId);
                camera.style.width = '100%';
                div.appendChild(camera)
                //屏幕
                let screen = document.createElement('div');
                screen.setAttribute('id','screen'+tracks[0].userId);
                screen.style.width = '100%';
                div.appendChild(screen)
  
                for (let track of tracks) {
                    if (track.info.kind === "video") {
                      if(track.info.tag=='screen'){
                        track.play(screen, true);
                      }else{
                        track.play(camera,true)
                      }
                    }
                }


      try {
        // 发布采集流
        await myRoom.publish(tracks)
        this.tracks =tracks
      } catch (e) {
        console.error(e)
        alert(`发布失败，ErrorCode: ${e.code}`)
      }


    },
    subscribeTracks (trackInfoList) {
     
      if(trackInfoList.length!==0){
        if(trackInfoList[0]['userId'].indexOf('educator')!=-1){
          myRoom.subscribe(trackInfoList.map(t => t.trackId)).then(tracks => {
            for(let track of tracks){
              if(track.info.kind==='video'){
                      let div = document.createElement('div');
                        div.setAttribute('id',track.userId);
                        div.style.width = '33%';
                        let educator = document.getElementById('educator');
                        educator.appendChild(div)
                        track.play(div,true)
              }else{
                let audio = document.getElementById('audio')
                track.play(audio)
              }
            }
          })
        }else{
                      let div = document.createElement('div');
                        div.setAttribute('id',trackInfoList[0].userId);
                        div.style.width = '33%';
                        let student = document.getElementById('student');
                        student.appendChild(div)
                        //摄像头
                        let camera = document.createElement('div');
                        camera.setAttribute('id','camera'+trackInfoList[0].userId);
                        camera.style.width = '100%';
                        div.appendChild(camera)
                        //屏幕
                        let screen = document.createElement('div');
                        screen.setAttribute('id','screen'+trackInfoList[0].userId);
                        screen.style.width = '100%';
                        div.appendChild(screen)
                        myRoom.subscribe(trackInfoList.map(t => t.trackId)).then(tracks => {
                        for (const track of tracks) {
                              
                                if (track.info.kind === "video") {
                            
                                    if(track.info.tag=='screen'){
                                      track.play(screen, true);
                                    }else{
                                      track.play(camera,true)
                                    }

                                }else{
                                  let audio = document.getElementById('audio')
                                  track.play(audio)
                                }

                        }
                    })
        }

      }
                
    },
    createRoomToken () {
      return new Promise((resolve,reject)=>{
        console.log(this.roomId)
        createPresentationRoomToken(this.roomId,this.application_id).then(res => {
          resolve(res.data)
        })
      })
      
    },
    listRoomUsers (e) {
      if(e){
        if(this.isInRoom==true){
          if(this.room_id!==''){
              listRoomUsers(this.room_id).then(res => {
                console.log(res)
                this.userList = res.data
              })
          }
        }
      }
    },
    changeView () {
      for (let item of this.userList) {
        if (item.userId == this.currentUser) {
          myRoom.setMergeStreamLayout(this.currentUser, {
            x: 0, y: 0, w: 1920, h: 1080, hidden: false, muted: false
          })
        } else {
           myRoom.setMergeStreamLayout(item.userId, {
            x: 0, y: 0, w: 0, h: 0, hidden: false, muted: false
          })
        }
      }
    },
   
    leaveRoom(){
      myRoom.leaveRoom();
      for (const track of this.tracks) {
        track.release();
      }
      this.$router.back(-1)
    },
    getPresentationInfo(){
      let student_id = getUserId()
      getPresentationInfo(student_id,'presentation').then(res=>{
        if(res.data.code===0){
          this.time_slot = res.data.data
        }
      })
    },
  
    // 上一页函数，
			prePage() {
				var page = this.pageNum
				page = page > 1 ? page - 1 : 1
				this.pageNum = page
			},
            // 下一页函数
			nextPage() {
				var page = this.pageNum
				page = page+1
				this.pageNum = page
			},
  }
}
</script>

<style scoped>
select {
  width: 300px;
}
.users{
    padding:20px
}

section {
  margin-bottom: 20px;
}

.qnrtc-stream-player {
  width: 100% !important;
  /* height: 1080px !important; */
  background: #000;
}

.smallWindow{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
</style>
